import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class PagePanels {
    constructor() {
        this.pagePanelsEl = null;
        this.panels = [];
    }

    init() {
        this.pagePanelsEl = document.querySelectorAll('.page-panels');

        this.pagePanelsEl.forEach((el) => {
            const panels = Array.from(el.querySelectorAll('.panel') || []);

            panels.forEach((panel, i) => {
                const trigger = ScrollTrigger.create({
                    trigger: panel,
                    start: () =>
                        panel.offsetHeight < window.innerHeight
                            ? "top top"
                            : "bottom bottom",
                    pin: i === panels.length - 1 ? false : true,
                    pinSpacing: false,
                });

                this.panels.push({ panel, trigger });

                // Apply background colour of element to parent ('pin-spacer') to fix the height round up issue where a
                // gap can be present if the height of the panel is not a full value, scrollTrigger will round up causing
                // a small gap to shoe
                const parent = panel.parentElement;
                const childBgColor = window.getComputedStyle(panel).backgroundColor;
                parent.style.backgroundColor = childBgColor;
            });
        });
    }

    loadAssets() {
        // Ensure that all assets have loaded
        this.waitForAssetsToLoad(() => {
            // After all assets have loaded, set up the resize listener
            this.setupResizeListener();

        });
    }

    waitForAssetsToLoad(callback) {
        const images = document.querySelectorAll("img");
        const totalImages = images.length;
        let loadedImages = 0;

        const imageLoadHandler = () => {
            loadedImages++;

            this.refeshPanels();

            if (loadedImages === totalImages) {
                // All images have loaded
                callback();
            }
        };

        images.forEach((image) => {
            if (image.complete) {
                imageLoadHandler();
            } else {
                image.addEventListener("load", imageLoadHandler);
            }
        });
    }

    setupResizeListener() {
        window.addEventListener("resize", () => {
            this.refeshPanels();
        });
    }

    refeshPanels() {
        // Refresh ScrollTrigger for all panels
        this.panels.forEach(({ trigger }) => {
            trigger.refresh();
        });
    }
}

export default new PagePanels();
