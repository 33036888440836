// Imports
import Alpine from 'alpinejs';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';

const navBreakpoint = 1024;
let viewportWidth = window.innerWidth;

class Navigation {
    constructor() {
        this.nav = document.querySelector('.mobile-nav');

        // Set up Alpine store to control nav toggle
        Alpine.store('nav', {
            open: false,
            toggle() {
                if (this.open) {
                    // Close nav

                    this.open = false;
                    console.log('Close nav');

                    // Enable scroll if viewport width is larger or equal than the breakpoint
                    if (viewportWidth < navBreakpoint) {
                        PageScrolling.unlock(this.nav);
                    }
                }
                else {
                    // Open nav

                    this.open = true;
                    console.log('Open nav');

                    // Disable scroll for smaller screens only
                    if (viewportWidth < navBreakpoint) {
                        PageScrolling.lock(this.nav);
                    }
                }
            }
        });

        // Close nav on element click
        const closeNavEls = document.querySelectorAll('[data-close-nav]');
        closeNavEls.forEach(el => {
            el.addEventListener('click', (e) => {
                if (Alpine.store('nav').open) {
                    Alpine.store('nav').toggle();
                }
            });
        });

        // Escape key press
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && Alpine.store('nav').open) {
                Alpine.store('nav').toggle();
            }
        });

        // Listen for viewport width changes
        window.addEventListener('resize', () => {
            viewportWidth = window.innerWidth;

            if (Alpine.store('nav').open) {
                // Check if the viewport width has changed
                if (viewportWidth < navBreakpoint) {
                    // Transition from larger to smaller viewport, lock scroll
                    console.log('Lock scroll based on viewport');
                    PageScrolling.lock(this.nav);
                } else if (viewportWidth >= navBreakpoint) {
                    // Transition from smaller to larger viewport, unlock scroll
                    console.log('Unlock scroll based on viewport');
                    PageScrolling.unlock(this.nav);
                }
            }
        });
    }
}

export default new Navigation();
