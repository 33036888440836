/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

import barba from '@barba/core';
import gsap from 'gsap';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import HeaderCalc from '@js/helpers/HeaderHeightCalc';
import ViewHeightCalc from '@js/helpers/ViewHeightCalc';

// Project modules
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import ScrollTo from '@js/modules/ScrollTo';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import CookieMessage from '@js/modules/CookieMessage';
import ExternalLinks from '@js/modules/ExternalLinks';
import Header from '@js/modules/Header';
import Newsletter from '@js/modules/Newsletter';
import Navigation from '@js/modules/Navigation';
import Page from '@js/modules/Page';
import PagePanels from '@js/modules/PagePanels';
import ProgressLine from '@js/modules/ProgressLine';

// Pages modules
import Team from '@js/pages/Team';


// HTML elememt classlist
const HTML_CLASSLIST = document.documentElement.classList;
const BODY_CLASSLIST = document.body.classList;
const HEADER_El = document.querySelector('.mn-hdr');
const PAGE_TRANSITION_SPEED = 0.7;

window.WELDON =
    window.WELDON ||
    new (function WELDON() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            ScrollYPos: 0,
            GoToScrollPos: false,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            ExternalLinks:  ExternalLinks,
            Page: Page,
            ProgressLine: ProgressLine,
            ScrollTo: ScrollTo,
        };

        // Pages
        this.pages = {
            team: Team,
        };


        let currentController;
        let currentClasses;
        let currentNameSpace;

        const beforeController = (data, done) => {
            console.log('beforeController');
            done();
        };

        const afterLeaveController = (data, done) => {
            console.log('afterLeaveController');
            done();
        };

        const leaveController = (container, done) => {
            HTML_CLASSLIST.add('_page-loading');
            HTML_CLASSLIST.remove('_page-loaded');

            // Stop scroll
            this.modules.PageScrolling.lock(document.body);

            // Show progress line
            this.modules.ProgressLine.show();

            // Fade out container
            gsap.to(container, {
                duration: this.env.reducedMotion ? 0 : PAGE_TRANSITION_SPEED,
                alpha: 0,
                onComplete: () => {

                }
            });

            // Leave the current page if exists
            currentController &&
            currentController.leave &&
            currentController.leave();

            done();
        };

        const enterController = (container, firstCall = false) => {
            // Scroll to top
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });

            // Enable scrolling
            this.modules.PageScrolling.unlock(document.body);

            // Modules
            this.modules.ScrollTo.build();

            if (!firstCall) {
                this.modules.ProgressLine.hide();
            }

            if (!this.env.reducedMotion) {
                this.modules.Appear.start();
                this.modules.AppearParent.start();
            }

            this.modules.PageScrolling.start();
            this.modules.PageScrollingManager.update(true);

            /**
             * Panels
             */
            PagePanels.init();

            if (!firstCall) {
                // Processes new content, enabling htmx behavior
                if (typeof htmx !== 'undefined') {
                    htmx.process(document.body);
                }

                // Reinit after assets have loaded
                PagePanels.loadAssets();
            }

            // Vars
            const nameSpace = container.getAttribute('data-spa-namespace');
            const pageColor = container.getAttribute('data-spa-page-color');
            const headerTitle = container.getAttribute('data-spa-header-title');
            const headerLink = container.getAttribute('data-spa-header-link');

            /**
             * Update page color
             */
            const changeColorEls = document.querySelectorAll('.change-bg-color');

            changeColorEls.forEach(element => {
                // Get the current classes
                const classes = element.classList;

                // Loop through the classes and check if they start with 'bg-is-'
                classes.forEach(className => {
                    if (className.startsWith('bg-is-')) {
                        // Remove the old class
                        classes.remove(className);

                        // Add the new class
                        classes.add(pageColor);
                    }
                });
            });

            /**
             * Update header message
             */
            if (!firstCall) {
                const headerTextEl = HEADER_El.querySelector('.hdr-title');

                // Fade out header message
                gsap.to(headerTextEl, {
                    duration: this.env.reducedMotion ? 0 : .3,
                    alpha: 0,
                    onComplete: () => {
                        let headerTitleContent = headerTitle;

                        if (headerLink) {
                            headerTitleContent = `<a href="${headerLink}">${headerTitle}</a>`;
                        }

                        // Change header message content
                        headerTextEl.innerHTML = headerTitleContent;

                        // Show header message
                        gsap.to(headerTextEl, {
                            duration: this.env.reducedMotion ? 0 : .3,
                            alpha: 1
                        });
                    }
                });
            }

            // Page controller
            const pageController = this.pages[nameSpace.toLowerCase()];
            if (pageController) {
                // Run page JS
                currentController = new pageController(container);
            } else {
                currentController = null;
            }

            // Test for old classes - if any, remove them
            if (currentClasses) {
                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.remove(cssClass);
                });
            }
            // Get new classes - if any, add them
            currentClasses = (container.getAttribute('data-spa-class') || null);
            if (currentClasses) {
                currentClasses = currentClasses.split(' ');

                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.add(cssClass);
                });
            }

            currentNameSpace && HTML_CLASSLIST.remove(currentNameSpace);
            currentNameSpace = '_page-' + nameSpace;
            HTML_CLASSLIST.add(currentNameSpace);

            HTML_CLASSLIST.remove('_page-loading');
            HTML_CLASSLIST.add('_page-loaded');

            // Update active links on page
            // Supports sections pages under sections
            Array.from(document.querySelectorAll('a:not(.exclude-active)')).forEach((link) => {
                if (
                    this.utils.testHrefIsActual(link.getAttribute('href'))
                    || window.location.href.includes(link.href)
                ) {
                    link.classList.add('_active');
                }
                else {
                    link.classList.remove('_active');
                }
            });

            // Mark external links
            this.helpers.DelayedCall.create(1000, () => {
                this.modules.ExternalLinks.build();
            });

            if (!firstCall) {
                gsap.from(container, {
                    duration: this.env.reducedMotion ? 0 : PAGE_TRANSITION_SPEED,
                    alpha: 0
                });

                // Test for Formie
                if (window.Formie) {
                    // Init forms so that all features are set up for the page
                    window.Formie.initForms();
                }
            }
        };

        const afterController = (next) => {
            if (window.WELDON.helpers.GoToScrollPos) {
                console.log('APPLY SCROLL');
                console.log(window.WELDON.helpers.ScrollYPos)
                window.scrollTo(0, window.WELDON.helpers.ScrollYPos);

                window.WELDON.helpers.GoToScrollPos = false;
            }

            // Reinit page functionality
            this.modules.Page.init();
        };

        const start = () => {
            HTML_CLASSLIST.remove('_page-loading');

            enterController(document.body.querySelector('[data-spa-namespace]'), true);

            HTML_CLASSLIST.add('_page-loaded');

            this.modules.Page.init();

            /**
             * Init Barba
             */
            barba.init({
                schema: {
                    prefix: 'data-spa',
                },
                sync: true,
                debug: false,
                prefetchIgnore: true,
                cacheIgnore: true,
                preventRunning: true,
                requestError: (trigger, action, url, response) => {
                    // Go to a custom 404 page if the user click on a link that return a 404 response status
                    if (action === 'click' && response.status && response.status === 404) {
                        barba.go('/404');
                    }

                    // Prevent Barba from redirecting the user to the requested URL
                    // this is equivalent to e.preventDefault() in this context
                    return false;
                },
                prevent: ({ el, event }) => {
                    event.stopPropagation();

                    console.log(el.getAttribute('data-external'));

                    if (el.getAttribute('data-external') != null || el.getAttribute('target') === '_blank') {
                        console.log('External link');
                        return true;
                    }

                    console.log('Internal link');

                    // Test if link is an anchor
                    if (el.getAttribute('href').startsWith('#')) {
                        console.log('Link is an anchor');
                        return true;
                    }
                    else {
                        // Is internal page link - Let Barba JS deal with it
                        event.preventDefault();
                        return false;
                    }
                },
                timeout: 30 * 1000,
                transitions: [{
                    async before(data) {
                        console.log('BEFORE');
                        const done = this.async();

                        beforeController(data, done);
                    },
                    async leave(data) {
                        console.log('LEAVE');
                        const done = this.async();

                        console.log('data');
                        console.log(data);
                        leaveController(data.current.container, done);
                    },
                    async afterLeave(data) {
                        console.log('AFTERLEAVE');
                        const done = this.async();

                        afterLeaveController(data, done);
                    },
                    async enter({ current, next }) {
                        console.log('ENTER');

                        enterController(next.container);
                    },
                    async after({ next }) {
                        console.log('AFTER');

                        afterController();

                        // GA page view tracking
                        if (typeof gtag === 'function') {
                            gtag('event', 'page_view', {
                                'page_title': document.title,
                                'page_location': location.href,
                                'page_path': location.pathname,
                            });
                        }
                    }
                }]
            });
        };

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            // Start SPA
            start();

            // Overrides the request class (default is `htmx-request`)
            if (typeof htmx !== 'undefined') {
                htmx.config.requestClass = 'loading';
            }

            // Show mobile nav element - To stop nav flashing at start
            document.querySelector('.mobile-nav').classList.remove('hidden');

            // Reinit search after swap
            if (typeof htmx !== 'undefined') {
                htmx.on('htmx:afterSettle', (event) => {
                    // window.reinitSearch();
                    this.modules.Page.init();
                });
            }
        });

        // Window Load
        window.addEventListener('load', e => {
            this.modules.PageScrollingManager.update(true);

            /**
             * Panels
             */
            PagePanels.refeshPanels();
            PagePanels.setupResizeListener();
        });

        // Popstate
        window.addEventListener('popstate', (event) => {
            // Log the state data to the console
            HTML_CLASSLIST.add('_popstate');

            // Enable scrolling
            this.modules.PageScrolling.unlock(document.body);

            // Close nav
            Alpine.store('nav').open = false;
        });

    })();




