class Team {
    constructor(container) {
        this.handleResize = this.handleResize.bind(this);
        window.addEventListener('resize', this.handleResize);

        this._adjustWidth();
    }

    enter() {
        this._adjustWidth();
    }

    handleResize() {
        this._adjustWidth(); // Re-run the enter function on resize
    }

    leave() {
        // Cleanup code when leaving the page
        window.removeEventListener('resize', this.handleResize);
    }

    _adjustWidth() {
        const teamAccEl = document.querySelector('.team-accolades');
        const headerTitle = document.querySelector('.hdr-title');
        const hdrContainer = document.querySelector('.hdr-container');

        // Check if the viewport width is 768px and above
        if (window.innerWidth >= 768) {
            // Reset values
            teamAccEl.style.removeProperty('width');

            // Adjust team accolades width to align them
            if (teamAccEl && headerTitle) {
                let headerTitleRect= headerTitle.getBoundingClientRect();
                let hdrContainerStyle = hdrContainer.currentStyle || window.getComputedStyle(hdrContainer);

                let difference = Math.abs(window.innerWidth - hdrContainer.offsetWidth);

                let width = ((window.innerWidth - headerTitleRect.left) - (difference / 2) - parseInt(hdrContainerStyle.paddingLeft)) + 'px';
                console.log((window.innerWidth - headerTitleRect.left));
                console.log(parseInt(hdrContainerStyle.paddingLeft));
                console.log({width});

                // Add width
                teamAccEl.style.width = width;
            }
        }
        else {
            // Remove width styles
            teamAccEl.style.removeProperty('width');
        }
    }
}

export default Team;
