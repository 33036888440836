// Imports
import Headroom from "headroom.js";

class Header {
    constructor() {
        const header = document.querySelector(".mn-hdr");

        if (header) {
            let headroom  = new Headroom(header, {
                    offset: 100,
                    tolerance: {
                        up: 8,
                        down: 0
                    }
                });

            // Initialise
            headroom.init();
        }
    }
}

export default new Header();



