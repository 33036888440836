// Imports
import InfiniteScroll from 'infinite-scroll';

// Project imports
import Appear from '@js/modules/Appear';

const attr = 'data-endless-scroll';

/**
 * Usage
 *
 * <div data-endless-scroll="news/p{{#}}">
 *     <div class="item">...</div>
 *     <div class="item">...</div>
 * </div>
 *
 * Set to a string with {{#}} for the incremental page number.
 * path: 'news/p{{#}}'
 * replace {{#}} with page number => news/p2, news/p3, news/p4
 */

class EndlessScroll {
    constructor() {}
    init() {
        const endlessScrollEl = document.querySelector(`[${attr}]`);

        if (endlessScrollEl) {
            const endlessScrollPath = endlessScrollEl.getAttribute(attr);

            let infScroll = new InfiniteScroll(endlessScrollEl, {
                path: endlessScrollPath,
                append: '.item',
                checkLastPage: '.endless-scroll-next',
                history: false,
            });

            infScroll.on('request', (path, fetchPromise) => {
                console.log('Request: ', path);
            });
            infScroll.on('append', (body, path, items, response) => {
                Appear.start();
            });
        }
    }
}

export default new EndlessScroll();
