// Imports
import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

class ProjectGrid {
    constructor() {
    }
    init() {
        const gridEl = document.querySelector('.projects-grid');

        if (gridEl) {
            let projectsMsnry = new Masonry(gridEl, {
                // Options
                itemSelector: '.grid-item'
            });

            // Layout Isotope after each image loads
            imagesLoaded(gridEl).on('progress', (image) => {
                console.log('Image loaded');
                console.log(image);
                this.relayout(projectsMsnry);
            });
        }
    }

    relayout(masnry) {
        setTimeout(() => {
            masnry.layout();
        }, 30);
    }
}

export default new ProjectGrid();
