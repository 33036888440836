// Project Imports
import PageScrolling from '@js/modules/PageScrolling';

class ProjectPanel {
    constructor() {

    }
    init() {
        const projectDetailPanel = document.querySelector('.project-details-panel');

        if (projectDetailPanel) {
            const projectDetailsPanelBtn = projectDetailPanel.querySelector('.project-details-panel-btn');

            projectDetailsPanelBtn.addEventListener('click', function(e) {
                const projectDetailPanel = e.target.closest('.project-details-panel');

                if (projectDetailPanel.classList.contains('_open')) {
                    // Close
                    projectDetailPanel.classList.remove('_open');

                    // Unlock scroll
                    PageScrolling.unlock(projectDetailPanel);
                }
                else {
                    // Open
                    projectDetailPanel.classList.add('_open');

                    // Lock scroll
                    PageScrolling.lock(projectDetailPanel);
                }
            });
        }

        document.addEventListener('keydown', function(e) {
            if (e.key === 'Escape') {
                const projectDetailPanel = document.querySelector('.project-details-panel');

                if (projectDetailPanel) {
                    if (projectDetailPanel.classList.contains('_open')) {
                        // Close
                        projectDetailPanel.classList.remove('_open');
                    }
                }
            }
        });
    }
}

export default new ProjectPanel();
